var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      attrs: { name: _vm.name },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_c("div", [_vm._v("Машины на парковке")])]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-row",
        { class: { "flex-w": _vm.isMobile } },
        [
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.list } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "culture",
                  label: "Культура",
                  "label-class-name": "tableTitles",
                  "class-name": "tableCellCulture",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "count",
                  label: "Количество авто",
                  "label-class-name": "tableTitles",
                  "class-name": "tableCellAutos",
                  align: _vm.alineColumn,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }