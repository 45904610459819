<template>
  <Dialog :name="name">
    <template #title>
      <div>Машины на парковке</div>
    </template>

    <el-row :class="{ 'flex-w': isMobile }">
      <el-table :data="list" style="width: 100%">
        <el-table-column
          prop="culture"
          label="Культура"
          label-class-name="tableTitles"
          class-name="tableCellCulture"
        />
        <el-table-column
          prop="count"
          label="Количество авто"
          label-class-name="tableTitles"
          class-name="tableCellAutos"
          :align="alineColumn"
        />
      </el-table>
    </el-row>
  </Dialog>
</template>

<script>
import { DIALOG_PARKING_INFO } from '@/constants/dialogs'
import Dialog from '@/UI/dialog/Dialog'
export default {
  name: 'DialogParkingInfo',
  components: { Dialog },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      name: DIALOG_PARKING_INFO,
    }
  },
  computed: {
    alineColumn() {
      return this.isMobile ? 'right' : 'center'
    },
  },
}
</script>
<style lang="sass">
@media (max-width: 415px)
#app .el-table .el-table__header tr .cell, #app .el-table .el-table__header th .cell.tableTitles
  font-size: 14px
#app .el-table .el-table__body tr td.tableCellCulture .cell
  font-size: 14px
</style>
